export const APPS_EMAIL = 'Applications@AntecControls.com'
export const APPS_PHONE = '1-866-884-3524'
export const APPS_HOURS = 'Monday - Friday, 8:00am - 4:30pm CT'
export const ANTEC_CANADA_ADDRESS = '638 Raleigh Street - Winnipeg, MB, R2K 3Z9'
export const ANTEC_USA_ADDRESS = '2975 Shawnee Ridge Court - Suwanee, Georgia 30024'
export const TRAINING_VIDEO_LINK = 'https://www.youtube.com/watch?v=3QnD2c4XovA'

export const APPLICATIONS_WEBSITE = 'https://anteccontrols.com/applications/'
export const TOGGLE_ALL_COLUMNS = 'Toggle All Columns'

export const UNITS_TYPE_STORAGE_LABEL = 'AntecControls_Units'

export const VV_MODEL = 'VV'
export const VFX_MODEL = 'VFX'
export const VVEC_MODEL = 'VVEC'
export const VVHWC_MODEL = 'VVHWC'

export const VV_TAB_NAME = VV_MODEL + ' Output'
export const VFX_TAB_NAME = VFX_MODEL + ' Output'
export const VVEC_TAB_NAME = VVEC_MODEL + ' Output'
export const VVHWC_TAB_NAME = VVHWC_MODEL + ' Output'

export const VFX_UNIT_SIZES = [106, 108, 110, 112, 114, 212, 214, 312, 314]
export const VV_UNIT_SIZES = [108, 110, 112, 114, 210, 212, 214, 312, 314, 412, 414]

export const VFX_UNIT_SIZES_DROPDOWN = VFX_UNIT_SIZES.join(', ')
export const VV_UNIT_SIZES_DROPDOWN = VV_UNIT_SIZES.join(', ')
export const VVHWC_UNIT_SIZES_DROPDOWN = '108, 110, 112, 114, 210, 212, 214, 312, 314, 412'
export const VVHWC_HEATING_COIL_CAPACITIES_WITH_DESCRIPTIONS = 'SC | Standard Capacity Hot Water Coil, HC | High Capacity Hot Water Coil, OSC | Oversized Standard Capacity Hot Water Coil, OHC | Oversized High Capacity Hot Water Coil'
export const VVHWC_HEATING_COIL_TYPES_WITH_DESCRIPTIONS = '1R | Right Hand One Row, 1L | Left Hand One Row, 2R | Right Hand Two Row, 2L | Left Hand Two Row, 3R | Right Hand Three Row, 3L | Left Hand Three Row, 4R | Right Hand Four Row, 4L | Left Hand Four Row'
export const VVHWC_FLUID_TYPES_WITH_DESCRIPTIONS = 'WTR | Water, PG | Propylene Glycol, EG | Ethylene Glycol'
export const VVHWC_SOLUTION_METHODS = {
  FLOW: 'Flow | Fluid Flow',
  CAPACITY: 'Capacity | Capacity',
  LAT: 'LAT | Leaving Air Temperature',
  LWT: 'LWT | Leaving Water Temperature',
  DROPDOWN: 'Flow | Fluid Flow, Capacity | Capacity, LAT | Leaving Air Temperature, LWT | Leaving Water Temperature'
}

export const LAT_SOLUTION_METHOD = 'LAT'
export const VFX_PRESSURE_DROP_HEADER = 'Pressure Drop'

export const VV_FIXED_COL_WIDTH = 115
export const VFX_FIXED_COL_WIDTH = 115
export const VVHWC_FIXED_COL_WIDTH = 130

export const IMPERIAL_UNITS = 'Imperial'
export const METRIC_UNITS = 'Metric'

export const UNITS = {
  IMPERIAL: {
    flow: 'CFM',
    pressure: 'in.w.c',
    temperature: '°F',
    fluidFlow: 'GPM',
    fluidPressureDrop: 'ft.w.g',
    coilCapacity: 'MBH',
    length: 'ft'
  },
  METRIC: {
    flow: 'L/s',
    pressure: 'Pa',
    temperature: '°C',
    fluidFlow: 'L/s',
    fluidPressureDrop: 'kPa',
    coilCapacity: 'kW',
    length: 'm'
  }
}

export const NO_VALVES_SIZES_FOUND_ERROR_MSG = 'No Valve Sizes Found'
